
import { Vue, Component } from 'vue-property-decorator';
import Mypage from '@/views/mypage/index.vue';

@Component({
  name: 'AppMypage',
  components: {
    Mypage,
  },
})
export default class extends Vue {
}
